import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const UsersIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M9 13c-3.76 0-8 1.387-8 3.857V19c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-2.143C17 14.387 12.76 13 9 13zm7.66.13c2.76.4 6.34 1.69 6.34 3.87v2c0 .552-.448 1-1 1h-3v-3c0-1.68-.97-2.94-2.34-3.87zM9 15l.229.003c.537.012 1.098.066 1.65.152l.47.08.46.094.447.105c1.538.39 2.744.966 2.744 1.423V18H3v-1.143c0-.457 1.206-1.034 2.744-1.423l.447-.105.46-.094.47-.08C7.753 15.057 8.395 15 9 15zM9 4c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm6 0c2.21 0 4 1.79 4 4s-1.79 4-4 4c-.43 0-.84-.09-1.23-.21-.03-.01-.06-.02-.1-.03C14.5 10.73 15 9.42 15 8s-.5-2.73-1.33-3.76C14.09 4.1 14.53 4 15 4zM9 6c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z'
            transform='translate(-817 -900) translate(817 900)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default UsersIcon
