import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CollectionsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M20 2c.552 0 1 .497 1 1.111V20.89c0 .614-.448 1.111-1 1.111H4c-.552 0-1-.497-1-1.111V3.11C3 2.497 3.448 2 4 2zm-1.982 16.001H5.998c-.512 0-.934.386-.991.883L5 19v1h14v-1.999c-.072.073-.399.073-.982 0zM19 4H5v12.001h13.017c.543 0 .983-.44.983-.982V4zM8.639 7.586c.852-.781 2.234-.781 3.086 0 .104.096.196.2.275.31.079-.11.17-.213.275-.31.852-.781 2.234-.781 3.086 0 .852.782.852 2.05 0 2.83L12 13.5l-3.361-3.083c-.852-.782-.852-2.05 0-2.83z'
            transform='translate(-732 -1347) translate(732 1347)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CollectionsIcon
