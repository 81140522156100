import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const RecipesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M16.5 3C19.465 3 22 4.352 22 6.4V20c0 1.333-2 1.333-2 0l-.007-.07c-.109-.594-1.564-1.33-3.493-1.33-2.006 0-3.5.797-3.5 1.4 0 1.333-2 1.333-2 0l-.007-.07c-.109-.594-1.564-1.33-3.493-1.33-2.006 0-3.5.797-3.5 1.4 0 1.333-2 1.333-2 0V6.4C2 4.352 4.535 3 7.5 3c1.779 0 3.403.487 4.418 1.319l.082.07.082-.07c.967-.793 2.486-1.272 4.165-1.316zm-9 2C5.494 5 4 5.797 4 6.4v10.938l.09-.045c.885-.415 1.982-.659 3.157-.69L7.5 16.6c1.312 0 2.54.265 3.502.738L11 6.4C11 5.797 9.506 5 7.5 5zm9 0c-2.006 0-3.5.797-3.5 1.4v10.937c.898-.442 2.03-.702 3.247-.734l.253-.003c1.27 0 2.462.248 3.41.693l.09.045V6.4c0-.58-1.38-1.339-3.27-1.397z'
            transform='translate(-395 -1609) translate(395 1609)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default RecipesIcon
