import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CopyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M16 2H5c-1.1 0-2 .9-2 2v13c0 .552.448 1 1 1s1-.448 1-1V4h11c.552 0 1-.448 1-1s-.448-1-1-1zm3 4H9c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 14H9V8h10v12z'
            transform='translate(-564 -570) translate(564 570)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CopyIcon
