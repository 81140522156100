import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const AddIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z'
            transform='translate(-396 -252) translate(396 252)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default AddIcon
