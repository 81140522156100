import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ActionsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M13.934 4.239c1.099-1.499 3.275-1.665 4.588-.35l1.581 1.581c1.315 1.314 1.149 3.49-.35 4.59l-5.93 4.35c-.914.669-2.1.761-3.1.284l-1.764 1.762c.165 1.205-.397 2.926-1.27 3.802-1.114 1.12-2.613.91-3.73-.212-1.133-1.14-1.356-2.583-.21-3.733.876-.88 2.597-1.447 3.8-1.278l1.75-1.75c-.476-.987-.394-2.188.285-3.114zM6.99 17.003l-.162.018c-.095.014-.195.032-.299.056-.57.132-1.105.388-1.362.646-.259.26-.225.476.21.913.407.41.663.445.896.211.256-.257.512-.796.643-1.372.024-.105.043-.206.057-.302l.016-.137.001-.033zM18.61 6.804l-6.033 6.033.063-.04 5.931-4.35c.51-.374.567-1.115.119-1.563l-.08-.08zm-1.502-1.502c-.447-.448-1.188-.391-1.562.12l-4.35 5.93c-.023.033-.045.066-.064.1l6.063-6.063z'
            transform='translate(-732 -1608) translate(732 1608)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default ActionsIcon
