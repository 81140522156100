import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CategoriesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M6.99 16c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm12 0c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm-6 0c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm6-6c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm-6 0c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm-6 0c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99v-1.98c0-.558.443-1.01 1.01-1.01h1.98zm12-6c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99V5.01c0-.558.443-1.01 1.01-1.01h1.98zm-6 0c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99V5.01c0-.558.443-1.01 1.01-1.01h1.98zm-6 0C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99V5.01C4 4.451 4.443 4 5.01 4h1.98z'
            transform='translate(-564 -1347) translate(564 1347)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CategoriesIcon
