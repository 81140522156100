import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const UploadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M12 2h.02c.023 0 .046.002.07.004L12 2c.05 0 .1.004.149.011l.052.01c.022.004.043.009.065.015l.046.014.06.021.051.023.061.03.037.022c.025.016.05.032.074.05l.022.017.008.007.082.073 4 4c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0L13 5.414V15.5c0 .552-.448 1-1 1s-1-.448-1-1V5.414L8.707 7.707c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l4-4 .082-.073.008-.007-.09.08c.035-.036.073-.068.112-.097.024-.018.049-.034.074-.05l.037-.021c.02-.011.04-.022.06-.031l.053-.023.06-.021.045-.014.065-.016.053-.009.058-.007.072-.004H12zm8 10c.552 0 1 .448 1 1v8c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-8.001c0-.552.447-.999.999-.999.551 0 .998.447.998.999V20H19v-7c0-.552.448-1 1-1z'
            transform='translate(-396 -770) translate(396 770)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default UploadIcon
