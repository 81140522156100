import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const EditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M5 19h2.586l10-10L15 6.414l-10 10V19zm-2 1v-4c0-.265.105-.52.293-.707l12-12c.39-.39 1.024-.39 1.414 0l4 4c.39.39.39 1.024 0 1.414l-12 12C8.52 20.895 8.265 21 8 21H4c-.552 0-1-.448-1-1z'
            transform='translate(-396 -470) translate(396 470)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default EditIcon
