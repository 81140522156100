import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const DeleteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M13.273 2C14.223 2 15 2.61 15 3.5V5h4.739C20.435 5 21 5.448 21 6s-.565 1-1.261 1H19v13c0 1.105-.895 2-2 2H7c-1.105 0-2-.895-2-2V7h-.739C3.565 7 3 6.552 3 6s.565-1 1.261-1H9V3.5c0-.89.777-1.5 1.727-1.5h2.546zM17 7H7v13.286h10V7zm-7 2c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1s-1-.448-1-1v-7c0-.552.448-1 1-1zm4 0c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1s-1-.448-1-1v-7c0-.552.448-1 1-1zm-1.182-5h-1.636v1h1.636V4z'
            transform='translate(-648 -252) translate(648 252)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default DeleteIcon
