import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ImageIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-6.13 11.068c-.116-.114-.396-.114-.573.07L9.4 18.183l-1.79-1.858c-.163-.169-.414-.182-.59-.04L5.156 19H17.79zM19 5H5v10.838l.563-.819.103-.126c.906-.94 2.35-.94 3.256 0l.479.497 2.585-2.684c.906-.941 2.35-.941 3.256 0L19 17.429V5zM9.75 7.5c.966 0 1.75.784 1.75 1.75S10.716 11 9.75 11 8 10.216 8 9.25 8.784 7.5 9.75 7.5z'
            transform='translate(-732 -252) translate(732 252)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default ImageIcon
