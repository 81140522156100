import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const MoreMenuIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 28 28' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M14 10c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
            transform='translate(-561 -766) translate(561 766) rotate(90 14 14)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default MoreMenuIcon
