import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g fillRule='evenodd'>
      <g fillRule='nonzero'>
        <g>
          <path
            d='M17.892 5.303c-.39-.39-1.02-.39-1.41 0l-4.89 4.88-4.89-4.89c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.89 4.89-4.89 4.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.89-4.89 4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41l-4.89-4.89 4.89-4.89c.38-.38.38-1.02 0-1.4z'
            transform='translate(-479 -252) translate(479 252)'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CloseIcon
